import { css } from '@emotion/core';
import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const StockTradingBanner = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query {
      Banner: file(relativePath: { eq: "asset-allocation/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ee2f79;
        height: 100%;
        ${Tablet} {
          display: flex;
          flex-direction: column;
          background-color: #ee2f79;
          width: 100%;
        }
        ${Desktop} {
          width: 100%;
          height: 615px;
          background-color: #ee2f79;
          display: flex;
          flex-direction: row;
          position: static;
          overflow: hidden;
          align-items: flex-start;
          position: relative;
          justify-content: center;
        }
      `}
    >
      {/* 字 */}
      <div
        css={css`
          width: 100%;
          ${Desktop} {
            max-width: 1200px;
          }
        `}
      >
        <div
          css={css`
            color: #000000;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: center;
            padding: 62px 0px 0 15px;
            ${Tablet} {
              color: #ffffff;
              padding: 55px 163px 0 30px;
            }
            ${Desktop} {
              position: static;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
              width: 50%;
              padding: 173px 48px 0 20px;
            }
          `}
        >
          <span
            css={css`
              font-size: 32px;
              font-weight: bold;
              line-height: 1.13;
              color: #ffffff;
              text-align: left;
              br {
                display: none;
              }
              ${Tablet} {
                font-size: 48px;
                font-weight: bold;
                line-height: 1.08;

                width: 575px;
                text-align: left;
              }
              ${Desktop} {
                width: 100%;
                font-size: 50px;
                font-weight: bold;
                line-height: 1;
                br {
                  display: block;
                }
              }
            `}
          >
            <FormattedHTMLMessage id='individuals.longTerm-assetAllocation.banner-title' />
          </span>
          <span
            css={css`
              font-size: 16px;
              font-weight: normal;
              line-height: 1.25;
              margin-top: 3px;
              width: 360px;
              color: #ffffff;
              text-align: left;
              ${Tablet} {
                margin-top: 20px;
                font-size: 24px;
                font-weight: normal;
                line-height: 1.38;
                width: 587px;
                text-align: left;
              }
              ${Desktop} {
                font-size: 25px;
                font-weight: normal;
                line-height: 1.2;
                margin-top: 63px;
                color: #ffffff;
                width: 100%;
              }
            `}
          >
            {intl.formatMessage({
              id: 'individuals.longTerm-assetAllocation.banner-description',
            })}
          </span>
        </div>
      </div>
      <div
        css={css`
          height: 76px;
          ${Tablet} {
            height: 40px;
          }
          ${Desktop} {
            height: 0;
          }
        `}
      ></div>
      {/* 图 */}
      <div
        css={css`
          width: 100%;
          ${Tablet} {
            overflow: hidden;
            width: 100%;
          }
          ${Desktop} {
            position: absolute;
            right: 0;
            width: 50%;
            height: 100%;
          }
        `}
      >
        <Img
          css={css`
            width: 140%;
            transform: translate(-20%, 0);
            ${Tablet} {
              width: 100%;
              transform: translate(0, 0);
            }
            ${Desktop} {
              width: 1200px;
              transform: translate(-225px, -60px);
            }
          `}
          fluid={data[`Banner`].childImageSharp.fluid}
          imgStyle={{ objectFit: 'fill' }}
          loading='eager'
        />
      </div>
    </div>
  );
};

export default StockTradingBanner;
