import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Container from '../../components/Container';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { useIntl } from 'gatsby-plugin-intl';
import AssetAllocationBanner from '../../components/AssetAllocationBanner';
import WhatYouGet from '../../components/StockTradingWhatYouGet';
import ProductEntry from '../../components/AssetAllocationProduct';
import HomeHowItWorks from '../../components/HomeHowItWorks';
import InvestmentOptions from '../../components/InvestmentOptions';
import Blank from '../../components/Blank';
// 【渐变背景】
const GradientBackground = ({ to = 'bottom', marginTop = [0, 0, 0] }) => {
  return (
    <div
      css={css`
        z-index: -5;
        background-image: linear-gradient(
          to ${to},
          rgba(242, 242, 242, 0),
          #f2f2f2
        );
        width: 100%;
        height: 146px;
        margin-top: ${marginTop[0]}px;
        ${Tablet} {
          height: 305px;
          margin-top: ${marginTop[1]}px;
        }
        ${Desktop} {
          height: 514px;
          margin-top: ${marginTop[2]}px;
        }
      `}
    ></div>
  );
};

const AssetAllocationPage = () => {
  const intl = useIntl();

  return (
    <Layout footerForm footerBand={false} footerBlank>
      <SEO
        title={intl.formatMessage({
          id: 'individuals.longTerm-assetAllocation.SEO.title',
        })}
        description={intl.formatMessage({
          id: 'individuals.longTerm-assetAllocation.SEO.description',
        })}
        keywords={intl.formatMessage({
          id: 'individuals.longTerm-assetAllocation.SEO.keywords',
        })}
      />
      <AssetAllocationBanner></AssetAllocationBanner>
      <Container>
        <Blank height={[100, 100, 150]} />
        <ProductEntry name='SGU' logoWidthList={[302.7, 492, 494]} />
        <Blank height={[80, 130, 140]} />
        <ProductEntry name='SG' logoWidthList={[302.7, 522, 429.16]} />
        <Blank height={[100, 150, 144]} />
        <HomeHowItWorks />
        <Blank height={[100, 200, 250]} />
        <div
          css={css`
            color: #161616;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            width: 293px;
            ${Tablet} {
              font-size: 36px;
              font-weight: 500;
              line-height: 1.33;
              width: 484px;
            }
            ${Desktop} {
              font-size: 36px;
              font-weight: 500;
              line-height: 0.92;
              width: 100%;
            }
          `}
        >
          {intl.formatMessage({
            id: 'individuals.longTerm-assetAllocation.what-you-get.title',
          })}
        </div>
        <Blank height={[20, 20, 50]} />
        <WhatYouGet advantages={['commission', 'flat-fee', 'IMPL']} />
      </Container>
      <GradientBackground marginTop={[158, 187, -425]} />
      <Blank height={[0, 0, 111]} />
      <Container
        css={css`
          overflow: normal;
        `}
      >
        <div
          css={css`
            color: #161616;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            margin-top: -204px;
            ${Tablet} {
              font-size: 36px;
              font-weight: 500;
              line-height: 1.33;
              margin-top: -298px;
              width: 512px;
            }
            ${Desktop} {
              font-size: 36px;
              font-weight: bold;
              line-height: normal;
              margin-top: 0;
              width: 100%;
            }
          `}
        >
          {intl.formatMessage({
            id: 'individuals.investment-options.other-title',
          })}
        </div>
        <Blank height={[10, 40, 49]} />
      </Container>
      <div
        css={css`
          width: 100%;
          margin-top: -138px;
          ${Tablet} {
            max-width: 970px;
            margin-top: -167px;
          }
          ${Desktop} {
            padding-left: 117px;
            max-width: 1440px;
            margin-top: 0;
          }
        `}
      >
        <InvestmentOptions doNotShow='LongTerm' />
      </div>
      <Blank height={[-10, 86, 100]} />
    </Layout>
  );
};

export default AssetAllocationPage;
