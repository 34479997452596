import React from "react";
import { css } from "@emotion/core";

const Section = ({ children }) => {
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        justify-content: center;
      `}
    >
      {children}
    </div>
  );
};

export default Section;
